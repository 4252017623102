<template>

  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visable"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
    </v-alert>


    <view-intro class="text-start" :heading="user.name"/>
    <div>
      <v-btn
        color="primary"
        elevation="2"
        outlined
        @click="$router.go(-1)"
      >&lArr; Terug
      </v-btn>
    </div>
    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <material-card
        class="mt-10 elevation-1"
        icon="fa-regular fa-user"
        icon-small
        :title="`Gebruiker informatie`"
        color="accent"
      >
        <v-card-text>
          <div style="display: flex; gap:15px; flex-wrap: wrap">
            <v-card
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="mb-2" style="display: flex; align-items: center;">
                    <i class="fa-regular fa-user mr-3 fa-2x"></i>
                    <div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ user.user.name }}
                      </v-list-item-title>
                      <a :href="`mailto:${user.user.email}`" class="text-overline mb-4">
                        {{ user.user.email }}
                      </a>
                      <br>
                      <a v-if="user.phoneNumber !== 'geen'" :href="`tel:${user.phoneNumber}`"
                         class="text-overline mb-4"
                      >
                        {{ user.phoneNumber }}
                      </a>
                    </div>
                  </div>
                  <v-list-item-subtitle>Gemaakt op: {{ user.user.UserMetaData.creationTime }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <!--TODO: show if there is one-->
            <v-card
              v-if="user.activeMemberschip"
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1" style="display: flex; align-items: center">
                    <i class="fa-regular fa-credit-card-blank mr-3 fa-2x"></i>
                    Doorloopende abbonoment
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Geldig van {{ user.activeMemberschip.startDate }} tot {{ user.activeMemberschip.endDate }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>

                <v-btn
                  outlined
                  rounded
                  text
                  :to="`/subscriptions/${user.activeMemberschip.id}`"
                  class="pl-4 pr-4"
                >
                  <i class="fa-regular fa-up-right-from-square mr-3 fa-1x"></i>
                  Bekijk abonnement
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card
              max-width="250"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1" style="display: flex; align-items: center">
                    <i class="fa-regular fa-clock mr-3 fa-2x"></i>
                    Laatst ingeloged
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ user.user.UserMetaData.lastSignInTime }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>


            <!--                        <v-card-->
            <!--              outlined-->
            <!--            >-->
            <!--              <v-list-item three-line>-->
            <!--                <v-list-item-content>-->
            <!--                  <v-list-item-title class="text-h5 mb-1" style="display: flex; align-items: center">-->
            <!--                    <i class="fa-regular fa-triangle-exclamation mr-3 fa-2x"></i>-->
            <!--                    {{ orderGroup.status }} status-->
            <!--                  </v-list-item-title>-->
            <!--                  &lt;!&ndash;                <div class="text-overline mb-4">&ndash;&gt;-->
            <!--                  &lt;!&ndash;                  Besteld op {{ $auth.createFulldate(orderGroup.date)}}&ndash;&gt;-->
            <!--                  &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--                  <v-list-item-subtitle>Besteld op {{ $auth.createFulldate(orderGroup.date) }}</v-list-item-subtitle>-->
            <!--                </v-list-item-content>-->
            <!--              </v-list-item>-->
            <!--              <v-card-actions>-->
            <!--                <div style="display: flex; flex-wrap: wrap; gap: 15px">-->
            <!--                  <v-btn-->
            <!--                    v-if="orderGroup.status !== 'closed'"-->
            <!--                    color="primary"-->
            <!--                    class="pl-4 pr-4"-->
            <!--                    rounded-->
            <!--                    @click="setAsClosed"-->
            <!--                  >-->
            <!--                    <i class="fa-regular fa-circle-check mr-3 fa-2x"></i>-->
            <!--                    Afgelevert-->
            <!--                  </v-btn>-->
            <!--                  <v-btn-->
            <!--                    v-if="orderGroup.status !== 'open'"-->
            <!--                    color="primary"-->
            <!--                    class="pl-4 pr-4"-->
            <!--                    rounded-->
            <!--                    @click="setAsOpen"-->
            <!--                  >-->
            <!--                    <i class="fa-regular fa-circle-exclamation mr-3 fa-2x"></i>-->
            <!--                    Maak open-->
            <!--                  </v-btn>-->
            <!--                  <v-btn-->
            <!--                    v-if="orderGroup.status !== 'interrupted'"-->
            <!--                    outlined-->
            <!--                    color="primary"-->
            <!--                    class="pl-4 pr-4"-->
            <!--                    rounded-->
            <!--                    @click="setAsInterrupted"-->
            <!--                  >-->
            <!--                    <i class="fa-regular fa-circle-xmark mr-3 fa-2x"></i>-->
            <!--                    Zet als onderbroken-->
            <!--                  </v-btn>-->

            <!--                </div>-->

            <!--              </v-card-actions>-->

            <!--            </v-card>-->
          </div>
        </v-card-text>
      </material-card>
      <material-card
        class="mt-10 elevation-1"
        icon="fa-regular fa-boxes-stacked"
        icon-small
        title="Bestellingen van gebruiker"
        color="accent"
      >
        <v-data-table
          @click:row="goToOrderDetail"
          :loading="loading"
          loading-text="Laden even geduld aub"
          :headers="orderHeaders"
          :items="orders"
          :items-per-page="10"
        ></v-data-table>
      </material-card>
      <div class="mt-10">
        <vue-form-generator ref="form" :schema="schema" :model="userModel" :options="formOptions"></vue-form-generator>
        <div class="mt-4">
          <v-btn
            color="primary"
            elevation="2"
            @click="handleSubmit"
          >Opslaan
          </v-btn>
        </div>
      </div>
      <material-card
        :heading="user.address.formatted"
        color="accent"
        class="mt-15"
      >
        <v-card-text>
          <v-sheet>
            <iframe
              :loading="loading"
              height="450"
              style="border:0"
              width="100%"
              loading="lazy"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://maps.google.com/maps?q=${user.address.formatted}&t=&z=13&ie=UTF8&iwloc=&output=embed`"
            >
            </iframe>
          </v-sheet>
        </v-card-text>
      </material-card>
      <div class="mt-4">
        <v-btn
          color="primary"
          elevation="2"
          outlined
          @click="$router.go(-1)"
        >&lArr; Terug
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
  import VueFormGenerator from 'vue-form-generator'

  export default {
    name: 'GebruikerDetail',
    data () {
      const { userUid } = this.$route.params
      if (!userUid) {
        this.$swal({
          title: 'Er is iets mis gegaan',
          icon: 'error',
        })
        this.$router.push('/users')
        return
      }
      this.$auth.getStoreUser(userUid).then(user => {
        if (!user) {
          this.$swal({
            title: 'Gebruiker is niet gevonden!',
            icon: 'error',
          })
          this.$router.push('/users')
          return
        }
        const filteredUser = this.$filter.filterStoreUser(user)
        this.$auth.populateMemberships(filteredUser).then(populatedUser => {
          if (!populatedUser) throw new Error('could not handle populating memberships')
          this.user = populatedUser
          this.userModel = this.user.user
          if (!this.user.ordersRefs.length) this.loading = false
          this.user.ordersRefs.forEach(async (ref, index) => {
            const orderGroup = await this.$auth.getOrderGroup(ref.groupId)
            if (orderGroup) {
              this.orders.push(orderGroup)
            }
            if (index + 1 === this.user.ordersRefs.length) {
              this.orders = this.$filter.filterOrderGroups(this.orders)
              this.loading = false
            }
          })
        })
      }).catch((err) => {
        this.alert.visable = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      })

      return {
        orderHeaders: [
          {
            text: 'Aantal',
            value: 'count',
          },
          {
            text: 'Datum',
            value: 'date',
          },
          {
            text: 'Totaal prijs',
            sortable: false,
            value: 'price',
          },
          {
            text: 'Status',
            value: 'status',
          },
        ],
        alert: {
          visible: false,
          description: '',
          button: {
            visible: false,
            text: null,
            cl: null,
          },
        },
        orders: [],
        loading: true,
        user: {},
        userModel: {
          name: '',
          email: '',
          phoneNumber: '',
        },
        schema: {
          fields: [
            {
              type: 'input',
              inputType: 'text',
              label: 'Naam',
              model: 'name',
              disabled: true,
            },
            {
              type: 'input',
              inputType: 'email',
              label: 'Email',
              model: 'email',
              disabled: true,
            },
            {
              type: 'input',
              inputType: 'text',
              label: 'Telefoon nummer',
              model: 'phoneNumber',
            },
          ],
        },
        formOptions: {
          validateAfterLoad: false,
          validateAfterChanged: true,
          validateAsync: true,
        },
      }
    },
    methods: {
      handleSubmit () {
        this.$auth.updateUser(this.user.uid, this.userModel).then((newUser) => {
          this.user = this.$filter.filterStoreUser(newUser)
          this.userModel = this.user.user
          this.$swal({
            title: `Gebruiker is bijgewerkt`,
            icon: 'success',
          })
        }).catch(() => {
          this.$swal({
            title: 'Er is iets mis gegaan met het bijgewerkt',
            icon: 'error',
            text: 'Neem graag contact op met beheerder',
          })
        })
      },
      goToSubscription (user) {
        // TODO: handle going to subscriptions
        console.log('goToSubscription')
      },
      goToOrderDetail (order) {
        this.$router.push(`/orders/${order.groupId}`)
      },
    },
  }
</script>
